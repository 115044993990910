import React, { useRef } from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";

import Layout from "../components/Layout";
import Button from "../components/Button";

import { bodyLargeStyles, device } from "../globalStyles";
import MovingBackground from "../components/MovingBackground";
import { useLangContext } from "../context/langContext";
import I18N from "../constants/languages/NL/nl_translations";
import Seo from "../components/SEO";

const StyledLayout = styled(Layout)`
  background: var(--blue);
  color: white;
`;

const Content = styled.div`
  position: relative;
  padding: 0 var(--gutter);
  margin-top: calc(-1 * var(--header-height));
  min-height: calc(
    100 * var(--vh) - var(--footer-height) - var(--header-height)
  );
`;

const TextContainer = styled.div`
  margin: auto;
  max-width: 872px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100 * var(--vh));
`;

const H2 = styled.h2`
  margin-bottom: 24px;
`;

const BodyLarge = styled.p`
  ${bodyLargeStyles};
`;

const StyledLink = styled(Link)`
  margin-top: 40px;
  text-decoration: none;
  width: 100%;

  @media (${device.minMobileL}) {
    width: unset;
  }

  & > button {
    width: 100%;
    @media (${device.minMobileL}) {
      width: 200px;
    }
  }
`;

const ReadyPage = () => {
  const backgroundRef = useRef(null);

  const { lang } = useLangContext();
  const copy = I18N[lang].ready;

  const handleTransition = (e) => {
    if (e.metaKey || e.ctrlKey) {
      return;
    } else {
      e.preventDefault();
    }
    if (backgroundRef.current) {
      backgroundRef.current.coverToRight(0.5, {
        onComplete: async () => await navigate("/play"),
      });
      // debugger;
    }
  };

  return (
    <StyledLayout title={copy.pageTitle} pageName="Ready to play?" transparent>
      <Content>
        <TextContainer>
          <H2>{copy.title}</H2>
          <BodyLarge>{copy.subtitle}</BodyLarge>
          <StyledLink to="/play" onClick={handleTransition}>
            <Button>{copy.button}</Button>
          </StyledLink>
        </TextContainer>
      </Content>
      <MovingBackground ref={backgroundRef} />
    </StyledLayout>
  );
};

export default ReadyPage;

export const Head = () => <Seo />;
